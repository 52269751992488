<template>
    <b-overlay
        :show="is_loading"
        variant='transparent'
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
    >
        <b-card no-body>
            <b-card-header>
                <b-card-title>
                   Добавить майнер
                </b-card-title>
            </b-card-header>
            
            <b-card-body>
                <validation-observer ref="card">
                    <b-form >

                        <b-row style="margin: 12px 0px;">
                            <b-col md="4" lg="4" style="padding: 0px">
                                <b-form-checkbox
                                    id="recommend"
                                    v-model="form.recommend"
                                    name="checkbox-1"
                                >
                                    Статус "рекомендуем"
                                </b-form-checkbox>
                            </b-col>
                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Основная информация
                        </div>

                        <b-row>
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Название майнера"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Название майнера</span>                             
                                        <b-form-input
                                          id="name"
                                          v-model="form.name"
                                          placeholder="ASIC Bitmain Antmainer T19"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                           
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label" >
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Производитель"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Производитель</span>                             
                                        <b-form-input
                                          id="manufacturer"
                                          v-model="form.manufacturer"
                                          placeholder="Bitmain"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Страна–изготовитель"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Страна–изготовитель</span>                             
                                        <b-form-input
                                          id="manufacturer_country"
                                          v-model="form.manufacturer_country"
                                          placeholder="Китай"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Состояние"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Состояние</span>                             
                                        <v-select
                                            :options="conditions"
                                            v-model="form.condition"
                                            label="title"
                                            :clearable="false"
                                            placeholder="Выбрать состояние"
                                        >
                                            <template slot="no-options">
                                                Нет результатов поиcка
                                            </template>
                                        </v-select>
                                        
                                    </validation-provider>
                                 </b-form-group>

                            </b-col>

                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                           Мощность
                        </div>

                        <b-row>
                            <b-col md="3" lg="3">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Хэшрейт"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Хэшрейт (TH/s)</span>                             
                                        <b-form-input
                                          id="hashrate"
                                          type="number"
                                          v-model="form.hashrate"
                                          placeholder="53"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="3" lg="3">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Погрешность хэшрейта"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Погрешность хэшрейта (%)</span>                             
                                        <b-form-input
                                          id="hashrate_error"
                                          type="number"
                                          v-model="form.hashrate_error"
                                          placeholder="10 %"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="3" lg="3">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Количество чипов"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Количество чипов</span>                             
                                        <b-form-input
                                          id="chips_count"
                                          type="number"
                                          v-model="form.chips_count"
                                          placeholder="342"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                            <b-col md="3" lg="3">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Количество хешплат"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Количество хешплат</span>                             
                                        <b-form-input
                                          id="hashboards_count"
                                          type="number"
                                          v-model="form.hashboards_count"
                                          placeholder="3"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="3" lg="3">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Модель чипов"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Модель чипов</span>                             
                                        <b-form-input
                                          id="chips_model"
                                          placeholder="BM1398BB"
                                          v-model="form.chips_model"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                           Электроэнергия
                        </div>

                        <b-row>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Энергопотребление"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Энергопотребление (W)</span>                             
                                        <b-form-input
                                          id="energy_usage"
                                          type="number"
                                          placeholder="3016"
                                          v-model="form.energy_usage"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Погрешность энергопотребления"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Погрешность энергопотребления (%)</span>                             
                                        <b-form-input
                                          id="energy_usage_error"
                                          type="number"
                                          placeholder="10 %"
                                          v-model="form.energy_usage_error"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Энергоэффективность"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Энергоэффективность (W)</span>                             
                                        <b-form-input
                                          id="energy_performance"
                                          type="number"
                                          placeholder="3016"
                                          v-model="form.energy_performance"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Входное напряжение"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Входное напряжение</span>                             
                                        <b-form-input
                                          id="input_voltage"
                                          placeholder="200-240 В"
                                          v-model="form.input_voltage"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Входной ток источника питания"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Входной ток источника питания</span>                             
                                        <b-form-input
                                          id="input_power_supply"
                                          placeholder="20 (1-3) А"
                                          v-model="form.input_power_supply"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Диапазон частот вх. напряжения"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Диапазон частот вх. напряжения</span>                             
                                        <b-form-input
                                          id="frequency_range"
                                          placeholder="47-63 Гц"
                                          v-model="form.frequency_range"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>


                        </b-row>


                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Температура
                        </div>

                        <b-row>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Рекомендуемая температура работы"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Рекомендуемая температура работы</span>                             
                                        <b-form-input
                                          id="recommended_working_temperature"
                                          placeholder="от 0 до 40"
                                          v-model="form.recommended_working_temperature"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Максимальная температура работы"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Максимальная температура работы</span>                             
                                        <b-form-input
                                          id="maximum_working_temperature"
                                          placeholder="70"
                                          v-model="form.maximum_working_temperature"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Температура хранения"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Температура хранения</span>                             
                                        <b-form-input
                                          id="storage_temperature"
                                          placeholder="от -20 до 70"
                                          v-model="form.storage_temperature"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>



                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Вес и размеры
                        </div>

                        <b-row>


                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Вес"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Вес</span>                             
                                        <b-form-input
                                          id="weight"
                                          type="number"
                                          placeholder="14.2"
                                          v-model="form.weight"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Размер без упаковки"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Размер без упаковки</span>                             
                                        <b-form-input
                                          id="unpacked_size"
                                          placeholder="400x195.5x290"
                                          v-model="form.unpacked_size"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Размер в упаковке"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Размер в упаковке</span>                             
                                        <b-form-input
                                          id="packed_size"
                                          placeholder="570x316x430"
                                          v-model="form.packed_size"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Стоимость
                        </div>

                        <b-row>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Стоимость"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Стоимость</span>                             
                                        <b-form-input
                                          id="price_amount"
                                          type="number"
                                          placeholder="3400 USD"
                                          v-model="form.price_amount"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Гарантия
                        </div>
                        
                        <b-row>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Гарантия на оборудование"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Гарантия 1 год (% от цены)</span>                             
                                        <b-form-input
                                          id="guarantee1y"
                                          type="number"
                                          placeholder="5 %"
                                          v-model="form.guarantee1y"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                            
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Гарантия на оборудование"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'text-danger' : errors.length > 0 ? true : false,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            
                                        }" style="font-size: 12px;">Гарантия 2 года (% от цены)</span>                                         
                                        <b-form-input
                                          id="guarantee2y"
                                          type="number"
                                          placeholder="10 %"
                                          v-model="form.guarantee2y"
                                        />
                                        <!---->
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Гарантия на оборудование"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'text-danger' : errors.length > 0 ? true : false,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            
                                        }" style="font-size: 12px;">Гарантия 3 года (% от цены)</span>                                         
                                        <b-form-input
                                          id="guarantee3y"
                                          type="number"
                                          placeholder="15 %"
                                          v-model="form.guarantee3y"
                                        />
                                        <!---->
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Гарантия на хешрейт"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'text-danger' : errors.length > 0 ? true : false,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            
                                        }" style="font-size: 12px;">Гарантия на хешрейт (% от цены)</span>                                         
                                        <b-form-input
                                          id="guaranteeths"
                                          type="number"
                                          placeholder="15"
                                          v-model="form.guaranteeths"
                                        />
                                        <!---->
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Дополнительно
                        </div>
                        
                        <b-row>

                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Период поставки"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">Период поставки (дней)</span>                             
                                        <b-form-input
                                          id="price_amount"
                                          type="number"
                                          placeholder="30"
                                          v-model="form.delivery_period"
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                            
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="Срок обслуживания"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'text-danger' : errors.length > 0 ? true : false,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            
                                        }" style="font-size: 12px;">Срок обслуживания (дней)</span>                             
                                        <b-form-input
                                          id="price_amount"
                                          type="number"
                                          placeholder="365"
                                          v-model="form.service_period"
                                        />
                                        <!---->
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>

                        </b-row>

                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            SEO
                        </div>

                        <b-row>
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label">
                                    <validation-provider
                                            #default="{ errors }"
                                            name="alt image"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">alt image</span>                             
                                        <b-form-input
                                          id="alt"
                                          v-model="form.media_alt"
                                          placeholder=""
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                           
                            <b-col md="4" lg="4">
                                <b-form-group class="form-group-with-label" >
                                    <validation-provider
                                            #default="{ errors }"
                                            name="title image"
                                            rules="required"
                                        >
                                        <span :class="{
                                            'text-muted' : errors.length > 0 ? false : true,
                                            'font-weight-bolder': errors.length > 0 ? true : false,
                                            'text-danger' : errors.length > 0 ? true : false
                                        }" style="font-size: 12px;">title image</span>                             
                                        <b-form-input
                                          id="manufacturer"
                                          v-model="form.media_title"
                                          placeholder=""
                                        />
                                        
                                    </validation-provider>
                                 </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <div class="text-black" style="font-weight: 500; font-size: 17px; margin: 12px 0px;">
                            Медиа
                        </div>
                        
                        <b-row>
                            
                            <b-col v-if="medias.length === 0" @click="preferMediaPickerAction" class="d-flex justify-content-center align-items-center flex-nowrap bg-gray" style="padding: 12px; height: 175px; cursor: pointer; border-radius: 4px; margin: 0 12px;">
                                <span class="text-muted">Кликните, чтобы добавить медиа объекты к публикации</span>
                            </b-col>
                            
                            <b-col v-else @click="preferMediaPickerAction" class="d-flex justify-content-start align-items-center flex-nowrap bg-gray" style="padding: 12px; height: 175px; cursor: pointer; border-radius: 4px; margin: 0 12px; overflow-y: hidden; overflow-x: scroll;">
                                <div v-for="(media, index) in medias" :key="index" @click="$event.stopPropagation()" :style="getMediaPlaceholderStyle(media)">
                                    
                                    <div @click="prefereMediaDeleteAction( $event, index )" class="bg-white" style="position: absolute; text-align: center; line-height: 30px; pointer: cursor; right: -10px; top: -10px; width: 32px; height: 32px; border-radius: 50%;">
                                        <feather-icon icon="XIcon" size="20" color="gray" />
                                    </div>
                                    
                                    <b-progress 
                                        v-if="media.id < 0"
                                        :value="media.progress.loaded" 
                                        :max="media.progress.total" 
                                        show-progress 
                                        animated
                                        style="width: 100%"
                                    ></b-progress>
                                    
                                </div>
                            </b-col>
                            
                        </b-row>

                        <div class="d-flex justify-content-end mt-2">
                            <b-button @click="add" variant="primary">Добавить в каталог</b-button>
                        </div>

                    </b-form>
                </validation-observer>    
            </b-card-body>
            
        </b-card>
    </b-overlay>
</template>

<script>

    import User from "@/modules/user/"

    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    import { required } from '@validations'
    import { pickFile } from 'js-pick-file';
    import $utils from "@/modules/utils/"

    import vSelect from "vue-select";
    
    export default {

        data() {
            return {
                
                required,
                
                is_loading: false,
                
                form: {
                    
                    name: "",
                    manufacturer: "",
                    
                    hashrate: null,
                    energy_usage: null,
                    energy_performance: null,
                    
                    chips_model: null,
                    chips_count: null,
                    
                    hashboards_count: null,
                    input_voltage: null,
                    input_power_supply: null,
                    
                    frequency_range: null,
                    
                    recommended_working_temperature: null,
                    maximum_working_temperature: null,
                    storage_temperature: null,
                    
                    
                    manufacturer_country: null,
                    weight: null,
                    packed_size: null,
                    unpacked_size: null,
                    
                    price_amount: null,
                    
                    delivery_period: null,
                    service_period: null,

                    hashrate_error: 10,
                    energy_usage_error: 10,

                    condition:  {id: 1, condition: '–', title: '–'},

                    media_alt: null,
                    media_title: null,
                    recommend: 0,

                    guarantee1y: 5,
                    guarantee2y: 10,
                    guarantee3y: 15,
                    guaranteeths: 15
                },
                
                medias: [],

                conditions: [
                    {id: 1, condition: '–', title: '–'},
                    {id: 2, condition: 'new', title: 'Новый'},
                    {id: 3, condition: 'used', title: 'БУ'},
                ]
                
            }
        },
        
        beforeRouteEnter(from, to, next ) {
            
            let perms = User.self.permissions.find(item => item.registry.key === 'miners' );
                        
            if( !perms ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'miners'
                    }
                });
            }
            
            if( perms.write === false ) {
                next({
                    name: 'admin-error-access-denied',
                    params: {
                        module: 'miners'
                    }
                });
            }
            
            next( true );
            
        },
        
        methods: {  
            
            
            getMediaPlaceholderStyle( media ) {
                
                return {
                    position: "relative",
                    display: 'flex',
                    alignItems: "center",
                    width: "125px",
                    minWidth: "125px",
                    height: "125px",
                    backgroundImage: `url(${media.thumb})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    borderRadius: "12px",
                    margin: "0 8px",
                    padding: "0 8px"
                }
                
            },
            
            prefereMediaDeleteAction(args, index ) {
                
                this.medias.splice( index, 1 );
                args.stopPropagation()
                
            },
            
            preferMediaPickerAction( args ) {
                
                let options = { 
                    accept: '.jpg, .jpeg, .png', 
                    multiple: true 
                }
                
                pickFile(options).then(files => {
                     files.forEach(file => {
                         
                        $utils.preferBeforeUploadAction( file ).then( data => {
                            
                            let object = {
                                id: -1,
                                type: data.type,
                                file: file,
                                fileName: file.name,
                                size: file.size,
                                extension: file.name.split('.').pop(),
                                duration: data.duration,
                                thumb: data.thumb,
                                placeholder: data.placeholder,
                                width: data.width,
                                height: data.height,
                                progress: {
                                    loaded: 0,
                                    total: 0
                                }
                                
                            }
                            
                            this.medias.push(object);
                            
                            this.$request.transfer("media.upload", {
                                type: data.type,
                                width: data.width,
                                height: data.height
                            }, file, ( progress ) => {
                                object.progress.total = progress.total;
                                object.progress.loaded = progress.loaded;
                            }).then( rsp => {
                                this.$set(object, "id", rsp.upload_id )
//                                object.id = rsp.upload_id;
                            });
                            
                        }); 
                         
                         
                     });
                });
                
            },
            
            add( args ) {
                               
                this.$refs.card.validate().then(success => {
                 
                    if( success === false ) return;

                    let medias =  this.medias.map( media => media.id );
                    this.form.condition = this.form.condition.condition
                    
                    this.$request.post("miners/add", {
                        form: this.form,
                        medias: medias
                    }).then( rsp => {
                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Майнер добавлен в каталог`,
                              text: `Майнер ${this.form.name} успешно добавлен в каталог`,
                              icon: 'CheckCircleIcon',
                              variant: 'success',
                            }
                        });
                        
                        this.medias = [];
                        
                        Object.keys( this.form ).forEach( key => {
                            if (key == 'recommend')  this.form[key] = false;
                            else this.form[key] = null;
                        });
                        
                    });   
                    
                });
                
            },
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        watch: {

        },
        mounted() {

        }

    }

</script>